import { useState, useEffect } from "react";
import axios from "axios";
import emailjs from "@emailjs/browser";
import { useSelector } from "react-redux";
import captureV3dContainerScreenshot from "../../../utils/captureV3dContainerScreenshot";
import {
  selectEmail,
  selectFullName,
  selectPhone,
  selectPostalCode,
  selectFeedbackMessage,
} from "../../../store/reducers/formInfoSlice";
import { selectSelectedId as selectSelectedModelId } from "../../../store/reducers/modelChoiceSlice";
import { selectDimensionsBySelectedId } from "../../../store/reducers/dimensionsSlice";
import { useFormattedDimensions } from "../SummaryFormComponent/dimensionsUtils";
import { selectShedOptionsState } from "../../../store/reducers/shedOptionsSlice";
import {
  translateIsGarageChecked,
  translateIsRoomChecked,
  translateSelectedGarageOption,
  translateSelectedRoomOption,
  translateIsShedPositionChecked,
  translateisPhotovoltaicsReinforcementChecked,
  translateShedPosition,
  translateIsSnowZoneChecked,
  translateIsMontageChecked,
  translateIsGrillTypeChecked,
  translateIsWallTypeChecked,
  translateIsGutterTypeChecked,
} from "../PDFGenerationUtils/choiceTranslationUtils/translateShedOptions";
import { selectColorChoices } from "../../../store/reducers/colorChoiceSlice";
import { translateColorCode } from "../PDFGenerationUtils/choiceTranslationUtils/translateColorCode";
import { selectSelectedId as isSelectedPanelColorId } from "../../../store/reducers/panelColorChoiceSlice";
import { translatePanelColorId } from "../PDFGenerationUtils/choiceTranslationUtils/panelColorTranslations";
import { selectSelectedId as selectSelectedPanelId } from "../../../store/reducers/panelChoiceSlice";
import { translatePanelId } from "../PDFGenerationUtils/choiceTranslationUtils/panelIdTranslations";
import { selectSelectedSquares } from "../../../store/reducers/panelPlacementSlice";
import { calculateSelectedSquares } from "../SummaryFormComponent/squaresCalculation";
import useGenerateOrderNumber from "../PDFGenerationUtils/choiceTranslationUtils/generateOrderNumber";
import getModelTypeText from "../PDFGenerationUtils/choiceTranslationUtils/modelTexts";


import {
  selectAntykondensatCost,
  selectDimensionPrice,
  selectFinalBruttoPrice,
  selectFinalPrice,
  selectGaragePrice,
  selectGutterPrice,
  selectMaskownicaPremiumPrice,
  selectMaskownicaPrice,
  selectPanelPrice,
  selectPanelsPrice,
  selectPhotovoltaicsReinforcementPrice,
  selectPolePrice,
  selectRoomPrice,
} from "../../../store/reducers/finalPriceSlice";

const useFirstAndSecondBusinessEmailRequest = (setIsLoading, resetCaptcha, setAlert) => {
  const email = useSelector(selectEmail);
  const fullName = useSelector(selectFullName);
  const phone = useSelector(selectPhone);
  const postalCode = useSelector(selectPostalCode);
  const feedbackMessage = useSelector(selectFeedbackMessage);
  const selectedModelId = useSelector(selectSelectedModelId);
  const generateOrderNumber = useGenerateOrderNumber();
  const orderNumber = generateOrderNumber();
  const dimensions = useSelector((state) =>
    selectDimensionsBySelectedId(state, selectedModelId)
  );
  const {
    formattedWidth,
    formattedLength,
    formattedHeight,
    formattedRoofDimension,
  } = useFormattedDimensions();
  const shedOptions = useSelector(selectShedOptionsState);
  const garageChecked = translateIsGarageChecked(shedOptions.isGarageChecked);
  const garageOption = translateSelectedGarageOption(
    shedOptions.selectedGarageOption
  );
  const shedChecked = translateIsRoomChecked(shedOptions.isRoomChecked);
  const shedOption = translateSelectedRoomOption(
    shedOptions.selectedRoomOption
  );
  const shedPositionChecked = translateIsShedPositionChecked(
    shedOptions.isShedPositionChecked
  );
  const montageChecked = translateIsMontageChecked(
    shedOptions.isMontageChecked
  );
  const shedPosition = translateShedPosition(shedOptions.shedPosition);
  const snowZoneChecked = translateIsSnowZoneChecked(
    shedOptions.isSnowZoneChecked
  );
  const photovoltaicsReinforcementChecked =
    translateisPhotovoltaicsReinforcementChecked(
      shedOptions.isPhotovoltaicsReinforcementChecked
    );
  const maskownicaPremiumChecked = translateIsGrillTypeChecked(
    shedOptions.isGrilleTypeChecked
  );
  const wallTypeChecked = translateIsWallTypeChecked(
    shedOptions.isWallTypeChecked,
    shedOptions.isGarageChecked,
    shedOptions.isRoomChecked
  );
  const rynnaChecked = translateIsGutterTypeChecked(
    shedOptions.isGutterTypeChecked
  );
  const colorChoices = useSelector(selectColorChoices);
  const poleColorName = translateColorCode(colorChoices.poleColor);
  const roofColorName = translateColorCode(colorChoices.roofColor);
  const facadeColorName = translateColorCode(colorChoices.facadeColor);
  const selectedPanelColorId = useSelector(isSelectedPanelColorId);
  const panelColorName = translatePanelColorId(selectedPanelColorId);
  const selectedPanelId = useSelector(selectSelectedPanelId);
  const translatedPanelId = translatePanelId(selectedPanelId);
  const selectedSquares = useSelector(selectSelectedSquares);
  const countOfSelectedSquares = calculateSelectedSquares(
    selectedSquares,
    selectedModelId,
    dimensions,
    shedOptions
  );

  const finalNettoPrice = useSelector(selectFinalPrice);
  const finalBruttoPrice = useSelector(selectFinalBruttoPrice);
  const dimensionPrice = useSelector(selectDimensionPrice);
  const panelPrice = useSelector(selectPanelPrice);
  const panelsPrice = useSelector(selectPanelsPrice);
  const photovoltaicReinforcementPrice = useSelector(
    selectPhotovoltaicsReinforcementPrice
  );
  const antykondensatCost = useSelector(selectAntykondensatCost);
  const roomPrice = useSelector(selectRoomPrice);
  const garagePrice = useSelector(selectGaragePrice);
  const maskownicaPrice = useSelector(selectMaskownicaPrice);
  const maskownicaPremiumPrice = useSelector(selectMaskownicaPremiumPrice);
  const polePrice = useSelector(selectPolePrice);
  const gutterPrice = useSelector(selectGutterPrice);
  let modelTypeText = getModelTypeText(selectedModelId);

  // Set the initial state with the fallback email
  const [contactEmail, setContactEmail] = useState("konfigurator@marciniakogrodzenia.pl");

  useEffect(() => {
    const fetchAndCheckDate = async () => {
      try {
        const response = await axios.get(
          "https://marciniakogrodzenia.pl/wp-content/uploads/2024/08/maile-daty.json"
        );
        const data = response.data;

        const rawCurrentDate = new Date();

        const formatter = new Intl.DateTimeFormat("en-GB", {
          timeZone: "Europe/Warsaw",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
        const formattedDateTime = formatter.format(rawCurrentDate);

        const [datePart, timePart] = formattedDateTime.split(', ');
        const [day, month, year] = datePart.split("/");
        const [hour, minute, second] = timePart.split(":");

        const currentDateTime = new Date(Date.UTC(year, month - 1, day, hour, minute, second));

        let emailToUse = "konfigurator@marciniakogrodzenia.pl";

        data.forEach((item) => {
          const [startDay, startMonth, startYear] = item["Data od"].split('.');
          const [endDay, endMonth, endYear] = item["Data do"].split('.');

          const [startHour, startMinute] = item["Godzina od piatek"].split(":");
          const [endHour, endMinute] = item["Godzina do sobota"].split(":");

          const startDateTime = new Date(Date.UTC(startYear, startMonth - 1, startDay, startHour, startMinute));
          const endDateTime = new Date(Date.UTC(endYear, endMonth - 1, endDay, endHour, endMinute));

          if (currentDateTime >= startDateTime && currentDateTime <= endDateTime) {
            emailToUse = item.Mail;
          }
        });

        setContactEmail(emailToUse);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAndCheckDate();
  }, []);

  const sendFirstAndSecondBusinessEmail = async () => {
    setIsLoading(true);
    const imageDataUrl = await captureV3dContainerScreenshot();
    const imageString = imageDataUrl.split(",")[1];


    const templateParams = {
      screenshotImage: imageString,
      fullName: fullName,
      phone: phone,
      contactEmail: contactEmail,
      userEmail: email,
      orderNumber: orderNumber,
      message: "This is a custom message you might want to include.",
      reply_to: email,
      modelType: getModelTypeText(selectedModelId),
      width: formattedWidth,
      length: formattedLength,
      height: formattedHeight,
      roofDimension: formattedRoofDimension,
      garageChecked: garageChecked,
      garageOption: garageOption,
      shedChecked: shedChecked,
      shedOption: shedOption,
      shedPositionChecked: shedPositionChecked,
      shedPosition: shedPosition,
      montageChecked: montageChecked,
      snowZoneChecked: snowZoneChecked,
      photovoltaicsReinforcementChecked: photovoltaicsReinforcementChecked,
      maskownicaPremiumChecked: maskownicaPremiumChecked,
      wallTypeChecked: wallTypeChecked,
      rynnaChecked: rynnaChecked,
      poleColorName: poleColorName,
      roofColorName: roofColorName,
      facadeColorName: facadeColorName,
      panelColorName: panelColorName,
      translatedPanelId: translatedPanelId,
      countOfSelectedSquares: countOfSelectedSquares,
      feedbackMessage: feedbackMessage,
      postalCode: postalCode,
      finalNettoPrice: finalNettoPrice,
      finalBruttoPrice: finalBruttoPrice,
      dimensionPrice: dimensionPrice,
      panelPrice: panelPrice,
      panelsPrice: panelsPrice,
      photovoltaicReinforcementPrice: photovoltaicReinforcementPrice,
      antykondensatCost: antykondensatCost,
      roomPrice: roomPrice,
      garagePrice: garagePrice,
      maskownicaPrice: maskownicaPrice,
      maskownicaPremiumPrice: maskownicaPremiumPrice,
      polePrice: polePrice,
      gutterPrice: gutterPrice,
    };

    // First request (business email)
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID_PROJECT,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (response) => {
          //console.log("Business email sent successfully");
        },
        (err) => {
          //console.error("Failed to send business email", err);
        }
      ) .finally(() => {
     
      });;

    // Second request (user email)
    const templateParams3 = {
      screenshotImage: imageString,
      fullName: fullName,
      phone: phone,
      contactEmail: contactEmail !== "konfigurator@marciniakogrodzenia.pl"
      ? "konfigurator@marciniakogrodzenia.pl"
      : "kontakt@marciniakogrodzenia.pl",
      userEmail: email,
      orderNumber: orderNumber,
      message: "This is a custom message you might want to include.",
      reply_to: contactEmail !== "konfigurator@marciniakogrodzenia.pl"
      ? "konfigurator@marciniakogrodzenia.pl"
      : email,

      modelType: modelTypeText,
      width: formattedWidth,
      length: formattedLength,
      height: formattedHeight,
      roofDimension: formattedRoofDimension,
      garageChecked: garageChecked,
      garageOption: garageOption,
      shedChecked: shedChecked,
      shedOption: shedOption,
      shedPositionChecked: shedPositionChecked,
      shedPosition: shedPosition,
      montageChecked: montageChecked,
      snowZoneChecked: snowZoneChecked,
      photovoltaicsReinforcementChecked: photovoltaicsReinforcementChecked,
      maskownicaPremiumChecked: maskownicaPremiumChecked,
      wallTypeChecked: wallTypeChecked,
      rynnaChecked: rynnaChecked,
      poleColorName: poleColorName,
      roofColorName: roofColorName,
      facadeColorName: facadeColorName,
      panelColorName: panelColorName,
      translatedPanelId: translatedPanelId,
      countOfSelectedSquares: countOfSelectedSquares,
      feedbackMessage: feedbackMessage,
      postalCode: postalCode,
      finalNettoPrice: finalNettoPrice,
      finalBruttoPrice: finalBruttoPrice,
      dimensionPrice: dimensionPrice,
      panelPrice: panelPrice,
      panelsPrice: panelsPrice,
      photovoltaicReinforcementPrice: photovoltaicReinforcementPrice,
      antykondensatCost: antykondensatCost,
      roomPrice: roomPrice,
      garagePrice: garagePrice,
      maskownicaPrice: maskownicaPrice,
      maskownicaPremiumPrice: maskownicaPremiumPrice,
      polePrice: polePrice,
      gutterPrice: gutterPrice,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID_PROJECT,
        templateParams3,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (response) => {
          //console.log("User email sent successfully");
        },
        (err) => {
          //console.error("Failed to send user email", err);
        }
      )
      .finally(() => {
     
      });
  };

  return { sendFirstAndSecondBusinessEmail };
};

export default useFirstAndSecondBusinessEmailRequest;
